import React, { useEffect } from "react"
import { Link } from "gatsby"
import MainLayout from "../layouts/MainLayout"
import Seo from "../components/seo"
import Lottie from "../components/lottie"
import Newsletter from "../components/newsletter"
import { navigationItems } from "../components/navigation/navigationItems"
import { templateItems } from "../components/templates/templateItems"

//Icons
import Accessibility from "../images/icons/home/accessible.svg"
import CopyPaste from "../images/icons/home/copy-paste.svg"
import Responsive from "../images/icons/home/responsive.svg"
import VanillaCode from "../images/icons/home/vanilla-code.svg"

//Images
import WindAndFigma from "../images/wuiAndFigma.webp"

export default function Home() {
  const HeroLottie = require("../images/lottie/hero_lottie.json")

  let componentsCount = 0
  let componentsVariations = 0

  navigationItems.forEach(category => {
    category.navigationSubCategories.forEach(subCategory => {
      if (!subCategory.comingSoon) {
        componentsCount += 1
        componentsVariations += subCategory.subCategoryVarNumber
      }
    })
  })

  let templateCount = 0

  templateItems.map(item => {
    if (!item.comingSoon) {
      templateCount += 1
    }
  })

  return (
    <MainLayout>
      <Seo
        title="Tailwind CSS Component Library | WindUI"
        ogtitle="Tailwind CSS Component Library | WindUI"
        description="Tailwind CSS Components Library - Streamline your Tailwind CSS projects with expertly made, responsive and accessible free UI Components in React and HTML."
        ogdescription="Tailwind CSS Components Library - Streamline your Tailwind CSS projects with expertly made, responsive and accessible free UI Components in React and HTML."
        url=" "
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Accordions, Tailwind CSS Alerts, Tailwind CSS Badges, Tailwind CSS Buttons, Tailwind CSS Breadcrumbs, Tailwind CSS Cards, Tailwind CSS Checkboxes, Tailwind CSS Input Groups, Tailwind CSS Modals, Tailwind CSS Pagination, Tailwind CSS, Progress Bars, Tailwind CSS Radio Groups, Tailwind CSS Select Menus, Tailwind CSS Tables, Tailwind CSS Tabs, Tailwind CSS Tooltips, Tailwind CSS Toggles, Tailwind CSS Switches, Tailwind CSS Spinners"
        robots="index, follow"
        revisit_after="7 days"
      />

      <section className="py-16 lg:py-24 ">
        <div className="container">
          {/* <!-- Component content --> */}
          <div className="grid items-center grid-cols-4 gap-6 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-6 ">
              <h1 className="pb-6 text-4xl font-semibold leading-tight text-wuiSlate-900 md:text-5xl lg:text-6xl">
                Streamline your Tailwind CSS projects
              </h1>
              <p className="pb-6 text-lg ">
                Expertly made, responsive, accessible components in React and
                HTML ready to be used on your website or app. Just copy and
                paste them on your Tailwind CSS project.
              </p>
              <div className="flex flex-col gap-4 pb-6 md:flex-row lg:gap-2">
                <div className="flex gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 8.25V18a2.25 2.25 0 0 0 2.25 2.25h13.5A2.25 2.25 0 0 0 21 18V8.25m-18 0V6a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 6v2.25m-18 0h18M5.25 6h.008v.008H5.25V6ZM7.5 6h.008v.008H7.5V6Zm2.25 0h.008v.008H9.75V6Z"
                    />
                  </svg>
                  <span>{templateCount} Templates</span>
                </div>
                <div className="flex gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                  <span>{componentsCount} Components</span>
                </div>
                <div className="flex gap-2">
                  <svg
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    strokeWidth="1.5"
                    className="w-6 h-6"
                  >
                    <path
                      d="M1.723,13.033A1.5,1.5,0,0,0,.75,14.438v5.625a1.5,1.5,0,0,0,.973,1.4l4.5,1.687a1.5,1.5,0,0,0,1.054,0l4.5-1.687a1.5,1.5,0,0,0,.973-1.4V14.438a1.5,1.5,0,0,0-.973-1.405l-4.5-1.687a1.5,1.5,0,0,0-1.054,0Z"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.008 13.597L6.75 15.75 12.492 13.597"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.75 15.75L6.75 23.25"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22.95,3.85l-8.7,2.9-8.7-2.9"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.25 6.75L14.25 10.5"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.25,8.25V4.75A1.5,1.5,0,0,1,6.276,3.327l7.5-2.5a1.493,1.493,0,0,1,.948,0l7.5,2.5A1.5,1.5,0,0,1,23.25,4.75v8.5a1.5,1.5,0,0,1-1.026,1.423L15.75,16.831"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>{componentsVariations} Variations</span>
                </div>
                <div className="flex gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z"
                    />
                  </svg>
                  <span>21 Colors</span>
                </div>
              </div>

              <div className="flex flex-col gap-4 md:flex-row lg:gap-2">
                <a
                  href="/components/"
                  className="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded whitespace-nowrap bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                >
                  <span>Explore components</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </span>
                </a>
                <a
                  href="/templates/"
                  className="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide transition duration-300 border rounded whitespace-nowrap border-emerald-500 text-emerald-500 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none"
                >
                  <span>View templates</span>
                </a>
              </div>
            </div>
            <div className="col-span-4 lg:col-span-6">
              <Lottie animationData={HeroLottie} />
            </div>
            {/* <img
              src={HeroIllustration}
              width={650}
              height={491}
              alt="Hero image"
              className=""
            /> */}
          </div>
        </div>
      </section>
      <section className="pb-16 lg:pb-24">
        <div className="container">
          <div className="grid items-center grid-cols-4 gap-x-6 gap-y-12 lg:grid-cols-12">
            <div className="self-start h-full col-span-4 text-center md:col-span-2 lg:col-span-3">
              <div className="h-full p-6 bg-white rounded-lg shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
                <div>
                  <img
                    className="m-auto"
                    width="60px"
                    height="60px"
                    src={Accessibility}
                    alt="accessibility icon"
                  />
                </div>
                <div className="pt-6">
                  <h2 className="pb-6 text-lg font-medium text-wuiSlate-800">
                    Accessible Components
                  </h2>
                  <p>
                    All components come with proper attributes to ensure full
                    accessibility with the WAI-ARIA standards.
                  </p>
                </div>
              </div>
            </div>
            <div className="self-start h-full col-span-4 text-center md:col-span-2 lg:col-span-3 ">
              <div className="h-full p-6 bg-white rounded-lg shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
                <div>
                  <img
                    className="m-auto"
                    width="60px"
                    height="60px"
                    src={CopyPaste}
                    alt="copy paste icon"
                  />
                </div>
                <div className="pt-6">
                  <h2 className="pb-6 text-lg font-medium text-wuiSlate-800">
                    Just Copy & Paste
                  </h2>
                  <p>
                    All components can be copied and pasted and easily
                    implemented in your tailwind css projects.
                  </p>
                </div>
              </div>
            </div>
            <div className="self-start h-full col-span-4 text-center md:col-span-2 lg:col-span-3 ">
              <div className="h-full p-6 bg-white rounded-lg shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
                <div>
                  <img
                    className="m-auto"
                    width="60px"
                    height="60px"
                    src={Responsive}
                    alt="responsive icon"
                  />
                </div>
                <div className="pt-6">
                  <h2 className="pb-6 text-lg font-medium text-wuiSlate-800">
                    Fully Responsive
                  </h2>
                  <p>
                    Every component is fully responsive and implemented to look
                    great at any screen size.
                  </p>
                </div>
              </div>
            </div>
            <div className="self-start h-full col-span-4 text-center md:col-span-2 lg:col-span-3 ">
              <div className="h-full p-6 bg-white rounded-lg shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
                <div>
                  <img
                    className="m-auto"
                    width="60px"
                    height="60px"
                    src={VanillaCode}
                    alt="vanilla code icon"
                  />
                </div>
                <div className="pt-6">
                  <h2 className="pb-6 text-lg font-medium text-wuiSlate-800">
                    Vanilla Tailwind
                  </h2>
                  <p>
                    The code is used in its original form without any
                    customization or third party dependencies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 lg:py-24">
        <div className="container">
          <div className="flex flex-col items-center justify-center gap-6 pb-12">
            <h2 className="text-3xl font-medium text-center text-wuiSlate-800">
              Tailwind CSS Components
            </h2>
            <p className="max-w-3xl text-center">
              Explore a collection of {componentsCount} responsive and
              accessible components, featuring {componentsVariations}{" "}
              variations, all crafted with React and HTML to seamlessly enhance
              your website or app.
            </p>
          </div>
          <div className="grid items-center grid-cols-4 pb-6 gap-x-6 gap-y-12 lg:grid-cols-12 lg:pb-12">
            {navigationItems.map((navigationCategory, index) => {
              return navigationCategory.navigationSubCategories.map(
                (navigationSubCategories, index) => {
                  return (
                    navigationSubCategories.featured && (
                      <Link
                        to={navigationSubCategories.subCategoryLink}
                        partiallyActive={true}
                        className={`col-span-4 overflow-hidden  rounded-lg bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 transition-shadow duration-500 hover:shadow-2xl hover:shadow-wuiSlate-300  md:col-span-2 lg:col-span-3 ${
                          navigationSubCategories.comingSoon
                            ? "cursor-no-drop shadow-md shadow-wuiSlate-200 hover:shadow-md hover:shadow-wuiSlate-200"
                            : ""
                        }`}
                        key={index}
                      >
                        {/*    <!-- Card header--> */}

                        <header className="flex flex-col gap-4 px-6 py-4">
                          <h2 className="flex items-center justify-between text-base font-medium text-wuiSlate-700 lg:text-lg">
                            {navigationSubCategories.subCategory}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                              />
                            </svg>
                          </h2>
                        </header>

                        {/*    <!-- Card image --> */}
                        <figure>
                          <img
                            src={navigationSubCategories.subCategoryImage}
                            alt="card image"
                            className="mx-auto aspect-[4/3]"
                          />
                        </figure>
                        <footer className="flex flex-col gap-4 px-6 py-4">
                          <div className="flex items-center justify-between">
                            {navigationSubCategories.subCategoryVarNumber && (
                              <h3 className="text-xs font-medium leading-5 uppercase text-wuiSlate-400">
                                {navigationSubCategories.subCategoryVarNumber}{" "}
                                variations
                              </h3>
                            )}
                            {navigationSubCategories.comingSoon && (
                              <div className="px-2 py-1 ml-auto text-xs uppercase rounded-md bg-wuiAmber-50 text-wuiAmber-500">
                                coming next
                              </div>
                            )}

                            {navigationSubCategories.new && (
                              <div className="px-2 py-1 text-xs uppercase rounded-md bg-wuiEmerald-100 text-wuiEmerald-500">
                                new
                              </div>
                            )}

                            {navigationSubCategories.updated && (
                              <div className="px-2 py-1 text-xs uppercase rounded-md bg-wuiCyan-50 text-wuiCyan-500">
                                updated
                              </div>
                            )}
                          </div>
                        </footer>
                      </Link>
                    )
                  )
                }
              )
            })}
          </div>
          <div className="text-center ">
            <Link
              to="/components/"
              id=""
              className="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded browse_components whitespace-nowrap bg-wuiEmerald-500 hover:bg-wuiEmerald-600 focus:bg-wuiEmerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-wuiEmerald-300 disabled:bg-wuiEmerald-300 disabled:shadow-none"
            >
              <span>See all components</span>
            </Link>
          </div>
        </div>
      </section>
      <section className="py-16 bg-white lg:py-24">
        <div className="container">
          <div className="grid items-center grid-cols-4 gap-6 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-6">
              <img src={WindAndFigma} alt="WindUI and Figma image" />
            </div>
            <div className="col-span-4 lg:col-span-6 ">
              <h2 className="pb-6 text-3xl font-medium text-wuiSlate-800">
                WindUI Design Library
              </h2>
              <p className="max-w-xl pb-12">
                Discover our extensive Figma design library, meticulously
                crafted to utilize Figma's latest features like auto-layout,
                variants, and variables for easy customization. Wind UI
                components seamlessly adapt to screens of varying sizes and
                ensure smooth functionality in both light and dark modes.
                Explore a wide range of design options with our vast collection
                of usage examples to speed up your project development.
              </p>

              <div className="flex flex-col gap-4 md:flex-row lg:gap-2">
                <a
                  href="https://www.figma.com/community/file/1367454154463499766/windui-design-library"
                  target="_blank"
                  className="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded whitespace-nowrap bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                >
                  <span>Preview in Figma</span>
                  <span className="relative only:-mx-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 lg:py-24">
        <div className="container">
          <div className="flex flex-col items-center justify-center gap-6 pb-12">
            <h2 className="text-3xl font-medium text-center text-wuiSlate-800">
              Templates
            </h2>
            <p className="max-w-3xl text-center">
              Discover our beautifully designed themes, built with WindUI
              components, perfect for developers, businesses, and personal
              projects.
            </p>
          </div>
          <div className="grid items-start grid-cols-4 gap-6 pb-12 lg:grid-cols-12">
            {templateItems.map((templateItem, index) => {
              return (
                templateItem.featured && (
                  <div
                    className={`col-span-4 flex h-full flex-col justify-between overflow-visible rounded-lg bg-white shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 transition-shadow duration-500 hover:shadow-2xl hover:shadow-wuiSlate-300 md:col-span-2 lg:col-span-4`}
                    key={index}
                  >
                    <div>
                      <figure className="relative">
                        {templateItem.themeSalePriceText && (
                          <div className="absolute top-0 right-0 px-6 py-2 text-sm font-medium text-white rounded-tr-lg rounded-bl-lg bg-wuiEmerald-500">
                            {templateItem.themeSalePriceText}
                          </div>
                        )}
                        <img
                          src={templateItem.themeImage}
                          alt="card image"
                          class="aspect-video w-full"
                        />
                      </figure>
                      <header className="flex flex-col gap-1 px-6 py-4">
                        <h2 className="flex items-center justify-between text-base font-medium text-wuiSlate-700 lg:text-lg">
                          {templateItem.theme}
                          {templateItem.comingSoon && (
                            <div className="px-2 py-1 ml-auto text-xs uppercase rounded-md bg-wuiAmber-50 text-wuiAmber-500">
                              coming soon
                            </div>
                          )}
                          {templateItem.new && (
                            <div className="px-2 py-1 text-xs uppercase rounded-md bg-wuiEmerald-100 text-wuiEmerald-500">
                              new
                            </div>
                          )}
                          {templateItem.updated && (
                            <div className="px-2 py-1 text-xs uppercase rounded-md bg-wuiCyan-50 text-wuiCyan-500">
                              updated
                            </div>
                          )}
                        </h2>
                        {templateItem.categories && (
                          <div className="flex flex-row flex-wrap gap-1 text-sm font-normal capitalize [&_a]:text-slate-500 [&_a]:transition-colors hover:[&_a]:text-slate-600 [&_a]:dark:text-slate-400 hover:[&_a]:dark:text-slate-300">
                            {templateItem.categories.map((category, index) => (
                              <span key={index}>
                                {category}
                                {index !== templateItem.categories.length - 1 &&
                                  ","}
                              </span>
                            ))}
                          </div>
                        )}
                      </header>
                      <div className="px-6">
                        <p>{templateItem.themeDescription}</p>
                      </div>
                    </div>

                    {templateItem.comingSoon ? (
                      ""
                    ) : (
                      <footer className="flex flex-col gap-4 px-6 py-8 overflow-hidden">
                        <div className="flex flex-col flex-wrap items-center gap-2 lg:flex-row lg:gap-3 2xl:flex-nowrap">
                          {templateItem.themeBuyLink && (
                            <a
                              href={templateItem.themeBuyLink}
                              target="_blank"
                              className="inline-flex items-center justify-center w-full h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded whitespace-nowrap bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                            >
                              <span>
                                Get it for{" "}
                                {templateItem.themeSalePrice ? (
                                  <>
                                    <span class="line-through">
                                      {templateItem.themePrice}
                                    </span>{" "}
                                    <span>{templateItem.themeSalePrice}</span>
                                  </>
                                ) : (
                                  templateItem.themePrice
                                )}
                              </span>
                              <span class="relative only:-mx-5">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="size-5"
                                  aria-label="Button icon"
                                  role="graphics-symbol"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                                  />
                                </svg>
                              </span>
                            </a>
                          )}
                          {templateItem.themePreviewLink && (
                            <a
                              href={templateItem.themePreviewLink}
                              target="_blank"
                              className="inline-flex items-center justify-center w-full h-10 gap-2 px-5 text-sm font-medium tracking-wide transition duration-300 border rounded whitespace-nowrap border-emerald-500 text-emerald-500 hover:border-emerald-600 hover:text-emerald-600 focus:border-emerald-700 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:text-emerald-300 disabled:shadow-none"
                            >
                              Live Preview
                            </a>
                          )}
                        </div>
                      </footer>
                    )}
                  </div>
                )
              )
            })}
          </div>
          <div className="text-center ">
            <Link
              to="/templates/"
              id=""
              className="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded browse_components whitespace-nowrap bg-wuiEmerald-500 hover:bg-wuiEmerald-600 focus:bg-wuiEmerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-wuiEmerald-300 disabled:bg-wuiEmerald-300 disabled:shadow-none"
            >
              <span>See all templates</span>
            </Link>
          </div>
        </div>
      </section>

      <section className="py-16 border-t bg-wuiSlate-200/10s border-wuiSlate-200 lg:pb-40 lg:pt-20">
        <Newsletter />
      </section>
    </MainLayout>
  )
}
