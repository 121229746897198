import addToMailchimp from "gatsby-plugin-mailchimp"
import React, { useState } from "react"
import Lottie from "../lottie"

export default ({}) => {
  const [email, setEmail] = useState("")
  const [submitted, setSubmitted] = useState(false)

  function errorHandling(data) {
    // Error Handling
  }

  const handleSubmit = () => {
    addToMailchimp(email).then(data => {
      if (data.result == "error") {
        errorHandling(data)
      } else {
        setSubmitted(true)
      }
    })
  }

  const Newsletter = require("../../images/lottie/newsletter.json")
  const Completed = require("../../images/lottie/completed.json")

  return (
    <>
      {submitted ? (
        <div className="">
          <div className="w-12 m-auto">
            <Lottie animationData={Completed} />
          </div>
          <h2 className="pb-6 text-2xl font-medium text-center text-wuiSlate-800">
            Welcome aboard!
          </h2>
          <p className="text-lg text-center">
            Thank you for subscribing! We will be in touch!
          </p>
        </div>
      ) : (
        <div className="container flex justify-center max-w-3xl">
          <div className="col">
            <div className="row">
              <div className="w-12 m-auto">
                <Lottie animationData={Newsletter} />
              </div>
              <h2 className="pb-6 text-3xl font-medium text-center text-wuiSlate-800">
                Join our Community
              </h2>
              <p className="text-lg text-center">
                Do you want to get notified when a new component is added to
                WindUI? Sign up for our newsletter and you will be among the
                first to find out about new features, components, versions, and
                tools.
              </p>
              <div className="relative flex flex-col max-w-xl gap-6 mx-auto my-6 md:flex-row">
                <input
                  id="id-b10newsletter"
                  type="email"
                  name="id-b10newsletter"
                  placeholder="Your email"
                  value={email}
                  className="relative w-full h-12 px-4 placeholder-transparent transition-all bg-transparent border rounded outline-none peer border-wuiSlate-200 text-wuiSlate-500 autofill:bg-transparent invalid:border-pink-500 invalid:text-pink-500 focus:border-wuiEmerald-500 focus:outline-none invalid:focus:border-pink-500 disabled:cursor-not-allowed disabled:bg-wuiSlate-50 disabled:text-wuiSlate-400"
                  onChange={e => setEmail(e.target.value)}
                />
                <label
                  htmlFor="id-b10newsletter"
                  className="absolute left-2 -top-2 z-[1] px-2 text-xs text-wuiSlate-500 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-slate-50 before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-wuiEmerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-wuiSlate-400 peer-disabled:before:bg-transparent"
                >
                  Enter your email
                </label>
                <button
                  onClick={() => handleSubmit()}
                  className="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded whitespace-nowrap bg-wuiEmerald-500 hover:bg-wuiEmerald-600 focus:bg-wuiEmerald-600 disabled:cursor-not-allowed disabled:border-wuiEmerald-300 disabled:bg-wuiEmerald-300 disabled:shadow-none"
                >
                  <span>Subscribe</span>
                </button>
              </div>
              <p className="text-xs text-center text-wuiSlate-400">
                By subscribing, you agree with Wind UI's{" "}
                <a
                  rel="nofollow noopener noreferrer"
                  className="text-wuiEmerald-500 hover:text-wuiEmerald-600"
                  href="/legal/terms"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  className="text-wuiEmerald-500 hover:text-wuiEmerald-600"
                  rel="nofollow noopener noreferrer"
                  href="/legal/privacy-policy"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
