import Empty from "../../images/components/empty.svg"
import Foxi from "../../images/templates/foxi.jpg"
import FoxiPro from "../../images/templates/foxi-pro.jpg"
import Agency from "../../images/templates/agency.jpg"

export const templateItems = [
  {
    theme: "Foxi Pro",
    themePreviewLink: "https://foxi-pro.netlify.app/",
    themeBuyLink:
      "https://oxygenna.lemonsqueezy.com/buy/2e32ec07-aa31-45a4-835d-8ebb7f6048cc",
    themeDescription:
      "A premium Tailwind CSS template with perfect PageSpeed, SEO optimization, light/dark modes, responsive design, and pre-built pages for fast, customizable development.",
    themeImage: FoxiPro,
    themePrice: "$79.99",
    featured: true,
    categories: ["Astro", "TailwindCSS"],
  },
  {
    theme: "Foxi",
    themePreviewLink: "https://foxi.netlify.app/",
    themeBuyLink: "https://github.com/oxygenna-themes/foxi-astro-theme",
    themeDescription:
      "Free Template with superb performance and amazing design, perfect for startups and SaaS websites, built with Astro & Tailwind CSS.",
    themeImage: Foxi,
    themePrice: "Free",
    categories: ["Astro", "TailwindCSS"],
    featured: true,
  },
  {
    theme: "Agency",
    themeDescription:
      "A modern, customizable template for agencies to easily showcase their services and portfolio online.",
    themeImage: Agency,
    themePrice: "Free",
    categories: ["Astro", "TailwindCSS"],
    comingSoon: true,
    featured: true,
  },
]
